<template>
  <div class="content">
    <div class="login">
    <van-field class="filed" type="text" v-model="account" placeholder="请输入用户名"/>
    <van-field @keypress="onKeyPress" class="filed" type="password" v-model="password" placeholder="请输入密码"/>
    <div class="login-btn" @click="login">登录</div>
  </div>
  </div>
</template>

<script>
import {pwdLogin} from '@/http/home'
import { Toast } from 'vant';
export default {
    data() {
        return {
            account: '',
            password: ''
        }

    },
methods: {
  async login() {
    if(this.account == '' || this.password == '') {
      Toast({
      message: '请输入账号密码',
      position: 'center',
    })
      return
    }
    let res = await pwdLogin({
        'adminaccount': this.account,
        'adminpassword': this.password
    })
    if(res.code == 200) {
        localStorage.setItem('token',res.data.token)
        this.$router.replace('/home')
    } 
  },
  onKeyPress(event) {
      // 检查是否是回车键
      if (event.key === 'Enter') {
        // 回车键被按下，执行相应的操作
        this.handleEnter();
      }
    },
    handleEnter() {
      // 在这里编写回车键按下时想要执行的代码
      this.login()
    }

}
}
</script>

<style lang="scss" scoped>
.content{
  background-color: rgb(18, 38, 49,);
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .login{
    width: 25rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #888888;
    border-radius: 0.5rem;
  }
  .filed{
    width: 80%;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
  }
  input{
    width: 80%;
    height: 3rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
  }
  .login-btn{
    width: 80%;
    height: 3rem;
    background-color: rgb(4, 104, 180);
    border-radius: 3rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem
  }
}

</style>