import request from "@/http/index.js";

export function getFactoryPriceList(data) {
  return new Promise(resove => {
    request({
      url: `/background/bigScreen/factoryPrice`,
      method: 'post',
      data: data
    }).then(res => {
      resove(res.data)
    })
  })
}

export function getCarList() {
  return new Promise(resove => {
    request({
      url: `/background/bigScreen/getCarList`,
      method: 'post',
    }).then(res => {
      resove(res.data)
    })
  })
}

export function getPortList() {
  return new Promise(resove => {
    request({
      url: `/background/bigScreen/getPortList`,
      method: 'post',
    }).then(res => {
      resove(res.data)
    })
  })
}

export function pwdLogin(data) {
    return new Promise(resove => {
      request({
        url: `/background/bigScreen/login`,
        method: 'post',
        data: data
      }).then(res => {
        resove(res.data)
      })
    })
  }
