import axios from 'axios';
import { Toast } from 'vant';

const request = axios.create({
    baseURL: 'https://api.grainlink.cn/service', // 开发 http://192.168.0.105:8091  正式 https://api.grainlink.cn/service  开发https://api.grainlink.cn/dev/service    
    timeout: 7200000 // 请求超时时间 5s
})

// 跨域请求，允许保存cookie
request.defaults.withCredentials = true

// HTTPrequest拦截
request.interceptors.request.use(config => {
  const token = localStorage.getItem("token");
  let user_id = localStorage.getItem("userId");

  if(token!=null&&token!=undefined&&token!=''){
    config.headers.Authorization = token
  }
  config.headers.userId = user_id 
  config.headers.appid = 'lc-app'
  config.headers.clientVersion = '1.0.0'
  config.headers.clientType = 'lcvue'

  console.log('请求url===============',config.url)
  console.log('请求参数===============',config.data)
  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
request.interceptors.response.use(res => {
  let message = res.data.message 
  console.log('返回结果===============',res.data)

  if (res.data.code == 503) {
    Toast({
      message: 'Token不存在或已过期',
      position: 'top',
    })
    localStorage.removeItem()
    return res
  }else if(res.data.code != 200&&res.data.code!=0&&message!=undefined&&message.length!=0){
    Toast({
      message: message,
      position: 'top',
    })
  }
  
  return res
}, error => {
  return Promise.reject(error);
})

export default request
