import Vue from 'vue'
import App from './App.vue'

import router from './router'

import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.config.productionTip = false

import vant from 'vant';
import 'vant/lib/index.css';

Vue.use(vant);
// Vue.use(SwipeItem);
// Vue.use(Toast);
// Vue.use(Icon);
// Vue.use(Field);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
