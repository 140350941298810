<template>
<div class="content">
    <dv-border-box-8>
      <div class="content-p">
       <div class="desc-row">
        <div class="logout" ></div>
         <div class="desc">
          <div class="title">山东粮策数据科技有限公司报价大屏</div>
          <div>{{timStr}}</div>
        </div>
        <div class="right">
          <div class="logout" @click="logout">退出登录</div>
          <div class="logout" @click="changeSpeed">设置</div>
        </div>
       </div>
        <div class="bottom">
      <div @click="changeType(1)" :class="showType == 1 ? 'active name' : 'name'">玉米价格</div>
      <div @click="changeType(2)" :class="showType == 2 ? 'active name' : 'name'">小麦价格</div>
      <div @click="changeType(3)" :class="showType == 3 ? 'active name' : 'name'">车辆情况</div>
      <div @click="changeType(4)" :class="showType == 4 ? 'active name' : 'name'">港口报价</div>
     </div>
       <!-- <div class="head-title">
       <dv-decoration-8 style="width:200px;height:50px;" />
       <div class="name">粮价通</div>
      <dv-decoration-8 :reverse="true" style="width:200px;height:50px;" />
       </div> -->
      <div class="list-title" :style="{ fontSize: titleFontSize + 'rem'}"> 
    <div class="name">{{showType == 1 || showType == 2 ?'厂家名称':showType == 3?'玉米厂家':'港口名称'}}</div>
    <div class="name">{{showType == 1 || showType == 2 ?'厂家地址':showType == 3?'玉米厂家地址':'港口地址'}}</div>
    <div class="name">{{showType == 1 || showType == 2 ?'昨日价格(元/斤)':showType == 3?'昨日数量':'昨日价格(元/吨)'}}</div>
    <div class="name">{{showType == 1 || showType == 2 ?'今日价格(元/斤)':showType == 3?'今日数量':'今日价格(元/吨)'}}</div>
    <div class="name">{{showType !=3 ?'涨跌情况':'对比'}}</div>
     </div>
     <div v-if="showType == 1" class="list">
   <vue-seamless-scroll :class-option="scrollOption" :data="ymList" class="warp">
    <div v-for="(item,index) in ymList" :key="index" :class="index%2==0?'list-item item1':'list-item item2'" :style="{ fontSize: titleFontSize + 'rem'}">
    <div class="name">{{item.factoryName}}</div>
    <div class="name">{{item.province}}{{item.city}}</div>
    <div class="name">{{item.yesterdayPrice}}</div>
    <div class="name">{{item.todayPrice}}</div>
    <div :class="item.floatState == 1 ? 'up name':item.floatState == 0?'down name' : 'name'">
      {{item.floatState==2?'持平':item.floatState==0?'跌'+item.floatPrice:'涨'+item.floatPrice}}
    </div>
   </div>
    </vue-seamless-scroll>
     </div>
      <div v-if="showType == 2" class="list">
   <vue-seamless-scroll :class-option="scrollOption" :data="xmList" class="warp">
    <div v-for="(item,index) in xmList" :key="index" :class="index%2==0?'list-item item1':'list-item item2'" :style="{ fontSize: titleFontSize + 'rem'}">
    <div class="name">{{item.factoryName}}</div>
    <div class="name">{{item.province}}{{item.city}}</div>
    <div class="name">{{item.yesterdayPrice}}</div>
    <div class="name">{{item.todayPrice}}</div>
    <div :class="item.floatState == 1 ? 'up name':item.floatState == 0?'down name' : 'name'">
      {{item.floatState==2?'持平':item.floatState==0?'跌'+item.floatPrice:'涨'+item.floatPrice}}
    </div>
   </div>
    </vue-seamless-scroll>
     </div>
      <div v-if="showType == 3" class="list">
   <vue-seamless-scroll 
   :class-option="scrollOption" 
   :data="carList" 
   class="warp">
    <div v-for="(item,index) in carList" :key="index" :class="index%2==0?'list-item item1':'list-item item2'" :style="{ fontSize: titleFontSize + 'rem'}">
    <div class="name">{{item.factoryName}}</div>
    <div class="name">{{item.province}}{{item.city}}</div>
    <div class="name">{{item.countYesterDay}}</div>
    <div class="name">{{item.numToday}}</div>
    <div :class="item.state == 1 ? 'up name':item.state == 0?'down name' : 'name'">
      {{item.state==2?'-':item.state==0?item.floatNumber:'+'+item.floatNumber}}
    </div>
   </div>
    </vue-seamless-scroll>
     </div>
      <div v-if="showType == 4" class="list">
   <vue-seamless-scroll :class-option="scrollOption" :data="portList" class="warp">
    <div v-for="(item,index) in portList" :key="index" :class="index%2==0?'list-item item1':'list-item item2'" :style="{ fontSize: titleFontSize + 'rem'}">
    <div class="name">{{item.portName}}</div>
    <div class="name">{{item.province}}{{item.city}}</div>
    <div class="name">{{item.yesterdayDunPrice}}</div>
    <div class="name">{{item.todayDunPrice}}</div>
    <div :class="item.floatState == 1 ? 'up name':item.floatState == 0?'down name' : 'name'">
      {{item.floatState==2?'持平':item.floatState==0?'跌'+item.floatDunPrice:'涨'+item.floatDunPrice}}
    </div>
   </div>
    </vue-seamless-scroll>
     </div> 
    </div>
  </dv-border-box-8>
  <van-popup v-model="showSet" position="top">
     <div class="pop-menu">
      <div class="changeSpeed">
          <div class="set-item">
            <div class="item-speed">速度</div>
            <van-slider :button-size="10" :min="0" :max="5" :step="0.1" v-model="step" />
          </div>
          <div class="set-item">
            <div class="item-font">字体大小</div>
            <van-slider :button-size="10" :min="0.1" :max="5" :step="0.1" v-model="titleFontSize" />
          </div>
     </div>
     </div>
  </van-popup>
  </div>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import dayjs from "dayjs"
import {
  getFactoryPriceList,
  getCarList,
  getPortList
} from "@/http/home";
  export default {
    name: 'Example01Basic',
    components: {
      vueSeamlessScroll
    },
    data () {
      return {
        ymList: [],
        xmList: [],
        carList: [],
        portList: [],
        isLoading:true,
        showType:1, //1玉米 2小麦 3 车辆 4 港口
        timer:null,
        timStr:null,
        typeIndex:0,
        step:0.3,
        titleFontSize: 0.9,
        showSet:false
      }
    },
     mounted(){
      let token = localStorage.getItem('token')
      if(!token){
        this.$router.replace('/login')
      }else{
      this.getList()
      this.timStr = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
      let index = 0;
      this.timer = setInterval(()=>{
        index++;
        this.typeIndex++;
        if(this.showType == 1){
          if(this.typeIndex == this.ymList.length){
            this.showType=2;
            this.typeIndex = 0;
          }
        }else if(this.showType == 2){
          if(this.typeIndex == this.xmList.length){
            this.showType=3;
            this.typeIndex = 0;
          }
        }else if(this.showType == 3){
          if(this.typeIndex == this.carList.length){
            this.showType=4;
            this.typeIndex = 0;
          }
        }else if(this.showType == 4){
          if(this.typeIndex == this.portList.length){
            this.showType=1;
            this.typeIndex = 0;
          }
        }
        // if(this.typeIndex == 60 * 5){
        //   this.showType++;
        //   if(this.showType == 5){
        //     this.showType = 1;
        //   }
        //   this.typeIndex = 0;
        // }
        this.timStr = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
        if(index == 10){
          index = 0;
          this.getList()
        }
      },1000)
      }
      
    },
    computed: {
      headTitle(){
        if(this.showType == 1){
          return '玉米价格'
        }else if(this.showType == 2){
          return '小麦价格'
        }else if(this.showType == 3){
          return '车辆价格'
        }else if(this.showType == 4){
          return '港口价格'
        }
       },
     scrollOption () {
    return {
      step: this.step, // 数值越大速度滚动越快
      limitMoveNum: 0, // 开始无缝滚动的数据量 this.list.length+1
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 0 // 单步运动停止的时间(默认值1000ms)
    }
  }
},
beforeDestroy () {
  console.log('清楚定时器')
  if (this.timer) {
    clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    this.timer = null;
  }
},

    methods:{
      changeSpeed(){
        this.showSet = true
      },
      logout(){
        localStorage.removeItem('token')
        this.$router.replace('/login')
      },
      getList(){
      this.getymList()
      this.getxmList()
      this.getCarList()
      this.getPortList()
      },
      changeType(type){
        this.showType = type;
        this.typeIndex = 0;
      },
    async getymList() {
      const res = await getFactoryPriceList({
        'factoryPriceType': 1
      });
      this.isLoading = false;
      if (res.code === 200) {
        this.ymList = res.data ?? [];
        this.ymList.forEach(element => {
        element.floatPrice = this.getUnit(element.floatPrice)
      });
      }
    },
     async getxmList() {
      const res = await getFactoryPriceList({
        'factoryPriceType': 2
      });
      this.isLoading = false;
      if (res.code === 200) {
        this.xmList = res.data ?? [];
        this.xmList.forEach(element => {
        element.floatPrice = this.getUnit(element.floatPrice)
      });
      }
    },
    async getCarList() {
      const res = await getCarList();
      this.isLoading = false;
      if (res.code === 200) {
        this.carList = res.data ?? []
        this.carList.forEach(element => {
          element.countYesterDay = element.countYesterDay == null ? 0 : element.countYesterDay
          if(element.numToday == null && element.numToday == 0){
            element.state = 2;
            element.numToday = element.numToday == null ? 0 : element.numToday
          }else if(element.numToday > element.countYesterDay){
            element.state = 1;
          }else if(element.numToday < element.countYesterDay){
            element.state = 0;
          }else{
            element.state = 2;
          }
        element.floatNumber = element.numToday - element.countYesterDay
      });
      }
    },
     async getPortList() {
      const res = await getPortList();
      this.isLoading = false;
      if (res.code === 200) {
        this.portList = res.data ?? []
      }
    },
    getUnit(num){
    if(num >= 1){
      return num + '元'
    }else if(num * 10 >= 1){
      return num * 100 / 10 + '毛'
    }else if(num * 100 >= 1){
      return num * 1000 / 10 + '分'
    }else{
      return num * 10000 / 10 + '厘'
    }
  },
}
  }
</script>

<style lang="scss" scoped>
.content{
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  background-color: rgb(0, 0, 0,);
  box-sizing: border-box;
  .pop-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    .changeSpeed{
    width: 80%;
    display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    .set-item{
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .item-speed{
        width: 2.5rem;
        margin-right: 0.5rem;
      }
      .item-font{
        width: 6rem;
         margin-right: 0rem;
      }
    }
  }
  }
  .content-p{
  padding: 2rem;
  padding-top: 1rem;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  }
  .desc-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
    font-weight: 600;
    color: #aaa;
    margin-bottom: 1rem;
  }
  .desc{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    .title{
      font-size: 1.4rem;
      margin-bottom: 0.2rem;
      color: rgb(5, 111, 182);
    }
  }
  .logout{
    width: 4rem;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.3rem;
  }
  
  .head-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    color: white;
    font-size: 1.2rem;
    font-weight: 900;
    .name{
      margin-left: 20rem;
      margin-right: 20rem;
    }
  }
  .list-title{
    // height: 3rem;
    padding: 0.2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(5, 111, 182);
    font-size: 0.9rem;
    .name{
      flex: 1;
      color: white;
    }
  }
   .list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // height: 3rem;
    padding: 1rem 0;
    font-size: 1.1rem;
    .name{
      flex: 1;
      color: white;
      
      // white-space: nowrap; /* 确保文本不会换行 */
      // overflow: hidden; /* 隐藏超出容器的文本 */
      // text-overflow: ellipsis; /* 在文本溢出处显示省略号 */
      font-weight: 400;
    }
    .up{
              color: #e41b11;
              // font-weight: 500;
            }
            .down{
              color: hsla(133, 89%, 28%, 0.925);
              // font-weight: 500;
             }
  }
  .item1{
     background-color: rgb(18, 38, 49,);
  }
  .item2{
    background-color: rgb(22, 58, 79,);
  }
  .list{
    overflow: hidden;
    height: 100%;
    flex: 1;
  }
  .warp {
    height: 100%;
    overflow: hidden;
  }
  .bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    .name{
      margin: 0 0.3rem;
    }
    .active{
    color: rgba(226, 25, 25, 0.787);
    font-weight: 600;
    font-size: 1.4rem;
  }
  }
  
}
  
</style>