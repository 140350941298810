import Vue from 'vue'
import VueRouter from 'vue-router' 

Vue.use(VueRouter)

import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router